<template>
  <div class="map">
    <div class="container" ref="container"></div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    farms: {
      type: Array,
      default() {
        return [];
      },
    },
    currentFarm: Object,
  },
  data() {
    return {
      map: null,
      //基地点集合
      farmPoint: new Map(),
      //基地数据集合
      farmBound: new Map(),
      //设备点集合
      farmSite: new Map(),
    };
  },
  watch: {
    farms() {
      this.addFarmDot();
    },
    currentFarm() {
      this.judgeMapChangeByFarm();
    },
  },
  methods: {
    initMap() {
      return new Promise((resolve) => {
        this.map = new BMapGL.Map(this.$refs.container, {
          mapType: BMAP_EARTH_MAP,
        });
        this.map.centerAndZoom(
          new BMapGL.Point(105.52314042411466, 29.475651579673745),
          11
        );
        this.map.enableScrollWheelZoom(true);
        resolve();
      });
    },
    mapLoad() {
      this.addRCMap();
      this.addFarmDot();
      let _self = this;
      this.map.addEventListener("zoomend", function (e) {
        _self.zoomEnd();
      });
    },
    zoomEnd() {
      let zoom = this.map.getZoom();
      this.$emit("zoom-change", zoom);
      if (zoom > 13) {
        for (let value of this.farmPoint.values()) {
          value.hide();
        }
        for (let value of this.farmBound.values()) {
          value.show();
        }
        for (let value of this.farmSite.values()) {
          value.show();
        }
      } else {
        for (let value of this.farmPoint.values()) {
          value.show();
        }
        for (let value of this.farmBound.values()) {
          value.hide();
        }
        for (let value of this.farmSite.values()) {
          value.hide();
        }
      }
    },
    addRCMap() {
      let boundary = new BMapGL.Boundary();
      boundary.get("重庆市荣昌区", (e) => {
        if (!e) return;
        for (let i = 0; i < e.boundaries.length; i++) {
          let point = e.boundaries[i];
          let points = point.split(";").map((item) => {
            return new BMapGL.Point(item.split(",")[0], item.split(",")[1]);
          });
          let polygon = new BMapGL.Polygon(points, {
            strokeColor: "#08dddd",
            fillColor: "#08dddd",
            strokeWeight: 2,
            strokeOpacity: 1,
            fillOpacity: 0.3,
            strokeStyle: "solid",
          });
          this.map.addOverlay(polygon);
        }
      });
    },
    addFarmDot() {
      if (!this.farms || this.farms.length == 0) return;
      for (let i = 0; i < this.farms.length; i++) {
        let farm = this.farms[i];
        let center = farm.mapCenterLocation;
        if (!center) continue;
        let point = new BMapGL.Point(
          center.split(",")[0],
          center.split(",")[1]
        );
        let key = farm.sourceId + "_label";
        this.farmPoint.set(
          key,
          new BMapGL.Label(
            `<img src="${
              farm.showPointCoverings
                ? farm.servicePath + farm.showPointCoverings
                : "imgs/location.png"
            }" style="width: 24px;height: 24px;cursor: pointer" />`,
            {
              position: point,
            }
          )
        );
        this.farmPoint.get(key).setStyle({
          background: "none",
          border: "none",
        });
        this.map.addOverlay(this.farmPoint.get(key));
        let textLabel = null;
        let _self = this;
        this.farmPoint.get(key).addEventListener("mouseover", function () {
          textLabel = new BMapGL.Label(farm.mapName, {
            position: point,
            offset: new BMapGL.Size(-60, -25),
          });
          textLabel.setStyle({
            background: "none",
            border: "none",
            color: "#000",
            textAlign: "center",
            width: "150px",
            fontSize: "16px",
          });
          _self.map.addOverlay(textLabel);
        });
        this.farmPoint.get(key).addEventListener("mouseout", function () {
          _self.map.removeOverlay(textLabel);
          textLabel = null;
        });
        this.farmPoint.get(key).addEventListener("click", function () {
          _self.$emit("farm-click", farm);
        });
      }
    },
    async addFarmBound() {
      // if (this.farmBound.has(this.currentFarm.sourceId)) return;
      for (let key of this.farmBound.keys()) {
        if (key.split("_")[0] == this.currentFarm.sourceId) return;
      }
      await this.getFarmView();
      await this.getFarmArea();
      await this.getFarmSite();
    },
    getFarmView() {
      return new Promise((resolve) => {
        this.$post(this.$api.BASE_MANAGE.VIEW, {
          id: this.currentFarm.sourceId,
        }).then((res) => {
          if (!res.mapPosition) return resolve();
          let mapPosition = JSON.parse(res.mapPosition);
          for (let i = 0; i < mapPosition.length; i++) {
            let temp = mapPosition[i];
            if (temp.type == "polygon") {
              let path = JSON.parse(temp.options.path);
              let points = path.map((item) => {
                return new BMapGL.Point(item.lng, item.lat);
              });
              this.farmBound.set(
                res.id + "_farm_" + i,
                new BMapGL.Polygon(points, {
                  fillColor: temp.options.fillColor,
                  fillOpacity: +temp.options.fillOpacity,
                  strokeColor: temp.options.strokeColor,
                  strokeOpacity: +temp.options.strokeOpacity,
                  strokeWeight: temp.options.strokeWeight,
                })
              );
              this.map.addOverlay(this.farmBound.get(res.id + "_farm_" + i));
            }
          }
          resolve();
        });
      });
    },
    getFarmArea() {
      return new Promise((resolve) => {
        this.$post(this.$api.PRODUCTION_AREA.LIST, {
          farmId: this.currentFarm.sourceId,
        }).then((res) => {
          if (res.list.length == 0) return resolve();
          for (let i = 0; i < res.list.length; i++) {
            let area = res.list[i];
            if (!area.mapPosition) continue;
            let mapPosition = JSON.parse(area.mapPosition);
            for (let j = 0; j < mapPosition.length; j++) {
              let temp = mapPosition[j];
              if (temp.type == "polygon") {
                let path = JSON.parse(temp.options.path);
                let points = path.map((item) => {
                  return new BMapGL.Point(item.lng, item.lat);
                });
                this.farmBound.set(
                  area.id + "_area_" + j,
                  new BMapGL.Polygon(points, {
                    fillColor: temp.options.fillColor,
                    fillOpacity: +temp.options.fillOpacity,
                    strokeColor: temp.options.strokeColor,
                    strokeOpacity: +temp.options.strokeOpacity,
                    strokeWeight: temp.options.strokeWeight,
                  })
                );
                this.map.addOverlay(this.farmBound.get(area.id + "_area_" + j));
              }
            }
          }
          resolve();
        });
      });
    },
    getFarmSite() {
      return new Promise((resolve) => {
        this.$store
          .dispatch("getSites", {
            farmId: this.currentFarm.sourceId,
            companyNo: this.currentFarm.companyNo,
          })
          .then((res) => {
            if (res.length == 0) return resolve();
            for (let i = 0; i < res.length; i++) {
              let site = res[i];
              let center = site.thirdLongLat;
              if (!center) continue;
              let point = new BMapGL.Point(
                center.split(",")[0],
                center.split(",")[1]
              );
              let key = site.guid + "_site_" + i;
              this.farmSite.set(
                key,
                new BMapGL.Label(
                  `<img src="${
                    site.logo
                      ? site.servicePath + site.logo
                      : "imgs/location.png"
                  }" style="width: 24px;height: 24px;cursor: pointer" />`,
                  {
                    position: point,
                  }
                )
              );
              this.farmSite.get(key).setStyle({
                background: "none",
                border: "none",
              });
              this.map.addOverlay(this.farmSite.get(key));
              let textLabel = null;
              let _self = this;
              this.farmSite.get(key).addEventListener("mouseover", function () {
                textLabel = new BMapGL.Label(site.name, {
                  position: point,
                  offset: new BMapGL.Size(-60, -25),
                });
                textLabel.setStyle({
                  background: "none",
                  border: "none",
                  color: "#000",
                  textAlign: "center",
                  width: "150px",
                  fontSize: "16px",
                });
                _self.map.addOverlay(textLabel);
              });
              this.farmSite.get(key).addEventListener("mouseout", function () {
                _self.map.removeOverlay(textLabel);
                textLabel = null;
              });
              this.farmSite.get(key).addEventListener("click", function () {
                _self.$emit("site-click", site);
              });
            }
          });
      });
    },
    judgeMapChangeByFarm() {
      if (!this.currentFarm) {
        // this.map.panTo(
        //   new BMapGL.Point(105.52314042411466, 29.475651579673745)
        // );
        // this.map.setZoom(11, {
        //   zoomCenter: new BMapGL.Point(105.52314042411466, 29.475651579673745),
        // });
        return;
      }
      this.addFarmBound();
      let center = this.currentFarm.mapCenterLocation.split(",");
      this.map.panTo(new BMapGL.Point(center[0], center[1]));
      this.map.setZoom(15, {
        zoomCenter: new BMapGL.Point(center[0], center[1]),
      });
    },
  },
  mounted() {
    this.initMap().then(() => {
      this.mapLoad();
    });
  },
};
</script>

<style lang="less" scoped>
.map {
  width: 100%;
  height: 100%;
  .container {
    width: 100%;
    height: 100%;
  }
}
</style>